import React from "react";
import { Typography, Layout, Divider } from "antd";

const { Title, Paragraph, Text } = Typography;
const { Content } = Layout;

const PrivacyPolicy = () => {
  return (
    <Layout style={{ background: "#fff", padding: "24px", minHeight: "100vh" }}>
      <Content style={{ maxWidth: "800px", margin: "0 auto" }}>
        <Title level={2}>Privacy Policy for WhatsApp Cloud API</Title>
        <Text type="secondary">Effective Date: 10th October 2024</Text>
        <Divider />

        <Paragraph>
          Tech For Good Pvt Ltd ("we," "our," or "us") is committed to
          protecting your privacy. This Privacy Policy explains how we collect,
          use, disclose, and safeguard your information when you interact with
          us via WhatsApp using the WhatsApp Cloud API. Please read this policy
          carefully to understand our practices regarding your information.
        </Paragraph>

        <Title level={3}>1. Information We Collect</Title>
        <Paragraph>
          When you contact us through WhatsApp, we may collect the following
          types of information:
          <ul>
            <li>
              <Text strong>Personal Information:</Text> Includes your name,
              phone number, and any other information you provide directly in
              your messages.
            </li>
            <li>
              <Text strong>Communication Content:</Text> The content of the
              messages you send to us, including any attachments or media files
              you share.
            </li>
            <li>
              <Text strong>Technical Information:</Text> We may collect
              information about your device and usage of the WhatsApp Cloud API,
              including your IP address, device type, and the date and time of
              your messages.
            </li>
          </ul>
        </Paragraph>

        <Title level={3}>2. How We Use Your Information</Title>
        <Paragraph>
          We use the information collected through WhatsApp for the following
          purposes:
          <ul>
            <li>
              To Respond to Inquiries: To answer questions, provide customer
              support, and address any issues you raise.
            </li>
            <li>
              To Process Orders and Provide Services: For managing requests,
              processing orders, and delivering products or services as
              requested.
            </li>
            <li>
              For Marketing and Promotions: To inform you about promotions,
              updates, or other information related to our services (with your
              consent, if required by law).
            </li>
            <li>
              To Improve Our Services: To enhance our customer experience and
              improve service efficiency.
            </li>
          </ul>
        </Paragraph>

        <Title level={3}>3. Sharing of Information</Title>
        <Paragraph>
          We do not sell or rent your information. However, we may share your
          information with:
          <ul>
            <li>
              Service Providers: We may share information with third-party
              vendors and service providers who assist us in providing our
              services, such as customer service and technical support.
            </li>
            <li>
              Legal Compliance: We may disclose information if required by law,
              legal process, or government request, or to protect the rights,
              property, and safety of our company, users, or others.
            </li>
          </ul>
        </Paragraph>

        <Title level={3}>4. Data Security</Title>
        <Paragraph>
          We take reasonable measures to protect your personal information
          against unauthorized access, alteration, disclosure, or destruction.
          Our WhatsApp Cloud API implementation complies with industry standards
          for data protection and security.
        </Paragraph>

        <Title level={3}>5. Data Retention</Title>
        <Paragraph>
          We retain your personal information only as long as necessary to
          fulfill the purposes outlined in this Privacy Policy, including any
          legal obligations. When no longer required, we securely delete or
          anonymize your information.
        </Paragraph>

        <Title level={3}>6. Your Privacy Rights</Title>
        <Paragraph>
          Depending on your jurisdiction, you may have certain rights regarding
          your personal information, including:
          <ul>
            <li>The right to access your data</li>
            <li>The right to request correction or deletion of your data</li>
            <li>
              The right to restrict or object to the processing of your data
            </li>
            <li>The right to data portability</li>
          </ul>
          Please contact us at <Text underline>[contact email]</Text> to
          exercise these rights. We will respond to your request in accordance
          with applicable laws.
        </Paragraph>

        <Title level={3}>7. Changes to This Privacy Policy</Title>
        <Paragraph>
          We may update this Privacy Policy from time to time. Changes will be
          posted on our website with the "Effective Date" at the top of this
          page. Your continued use of our WhatsApp service after any changes
          constitutes your acceptance of the updated policy.
        </Paragraph>

        <Title level={3}>8. Contact Us</Title>
        <Paragraph>
          If you have questions or concerns about this Privacy Policy or our
          data practices, please contact us at:
        </Paragraph>
        <Paragraph>
          Tech For Good Pvt Ltd
          <br />
          Marine House, #02 - 01, 53 Dharmapala Mawatha
          <br />
          Colombo 03, Sri Lanka
          <br />
          Email: <Text underline>vijitha.wijesuriya@gmail.com</Text>
          <br />
          Phone: +94777322500
        </Paragraph>
      </Content>
    </Layout>
  );
};

export default PrivacyPolicy;
