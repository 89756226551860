import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { NotFound } from "./utils/notFound";
import { ScrollToTop } from "./utils/scrollToTop";
import { NavBar } from "./components/navigation/navBar";
import { LandingPage } from "./pages/LandingPage";
import { Details } from "./components/techTalent/details";
import { DetailsMission } from "./components/mission/detailsMission";
import { Resources } from "./pages/Resources";
import { Hosting } from "./components/resources/hosting";
import { AppDevelopment } from "./components/resources/appDevelopment";
import { Stages } from "./components/landingPage/stages";
import { Admin } from "./pages/Admin";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import WhatsAppInterface from "./pages/WhatsAppInterface";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <NavBar />
      <Routes>
        <Route exact path="/" element={<LandingPage />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/resources" element={<Resources />}>
          <Route path="app-development" element={<AppDevelopment />} />
          <Route path="hosting" element={<Hosting />} />
        </Route>
        <Route path="/stages" element={<Stages />} />
        <Route path="/admin" element={<Admin />} />
        <Route exact path="/tech-talent" element={<Details />} />
        <Route exact path="/mission" element={<DetailsMission />} />
        <Route exact path="/*" element={<NotFound />} />
        <Route exact path="/whatsApp" element={<WhatsAppInterface />} />
      </Routes>
    </Router>
  );
}

export default App;
