import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Layout,
  List,
  Typography,
  Input,
  Button,
  Form,
  Spin,
  Avatar,
  message as antMessage,
} from "antd";
import { SendOutlined, UserOutlined } from "@ant-design/icons";
import axios from "axios";

const { Header, Content, Footer, Sider } = Layout;
const { Text } = Typography;
const { TextArea } = Input;

const WhatsAppInterface = () => {
  const [messages, setMessages] = useState({});
  const [contacts, setContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState("");
  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false);
  const contentRef = useRef(null);

  const fetchMessages = useCallback(async () => {
    try {
      const [receivedResponse, sentResponse] = await Promise.all([
        fetch("/.netlify/functions/getWhatsAppMessages"),
        fetch("/.netlify/functions/getSentMessages"),
      ]);

      const receivedData = await receivedResponse.json();
      const sentData = await sentResponse.json();

      console.log(receivedData);

      const combinedMessages = {};

      receivedData.messages.forEach((msg) => {
        if (!combinedMessages[msg.from]) {
          combinedMessages[msg.from] = [];
        }
        combinedMessages[msg.from].push({
          id: msg.id,
          ...msg,
          direction: "incoming",
        });
      });

      sentData.sentMessages.forEach((msg) => {
        if (!combinedMessages[msg.to]) {
          combinedMessages[msg.to] = [];
        }
        combinedMessages[msg.to].push({
          id: msg.id || `${msg.timestamp}-${msg.text}`,
          ...msg,
          direction: "outgoing",
        });
      });

      for (let contact in combinedMessages) {
        combinedMessages[contact].sort(
          (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
        );
      }

      setMessages(combinedMessages);
      setContacts(Object.keys(combinedMessages));
      if (!selectedContact && Object.keys(combinedMessages).length > 0) {
        setSelectedContact(Object.keys(combinedMessages)[0]);
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
      antMessage.error("Failed to load messages.");
    } finally {
      setLoading(false);
    }
  }, [selectedContact]);

  useEffect(() => {
    setLoading(true);
    fetchMessages();

    const interval = setInterval(fetchMessages, 5000);
    return () => clearInterval(interval);
  }, [fetchMessages]);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = contentRef.current.scrollHeight;
    }
  }, [messages, selectedContact]);

  const markMessagesAsRead = useCallback(
    async (read = true) => {
      if (messages[selectedContact]) {
        try {
          const targetMessages = messages[selectedContact].filter(
            (msg) => msg.direction === "incoming" && msg.read !== read
          );

          for (const msg of targetMessages) {
            await axios.post("/.netlify/functions/updateWhatsAppMessage", {
              messageId: msg.id,
              read,
            });

            setMessages((prevMessages) => ({
              ...prevMessages,
              [selectedContact]: prevMessages[selectedContact].map((message) =>
                message.id === msg.id ? { ...message, read } : message
              ),
            }));
          }
        } catch (error) {
          console.error(
            `Error marking messages as ${read ? "read" : "unread"}:`,
            error
          );
        }
      }
    },
    [messages, selectedContact]
  );

  useEffect(() => {
    if (selectedContact) {
      markMessagesAsRead(true); // Mark messages as read when the contact is selected
    }
  }, [selectedContact, messages, markMessagesAsRead]);

  const sendMessage = async (values) => {
    const { message } = values;
    setSending(true);

    try {
      await axios.post("/.netlify/functions/sendWhatsAppMessage", {
        to: selectedContact,
        text: message,
      });

      const newMessage = {
        id: `${new Date().toISOString()}-${message}`,
        from: "me",
        to: selectedContact,
        text: message,
        timestamp: new Date().toISOString(),
        direction: "outgoing",
      };

      setMessages((prevMessages) => ({
        ...prevMessages,
        [selectedContact]: [
          ...(prevMessages[selectedContact] || []),
          newMessage,
        ],
      }));
    } catch (error) {
      console.error("Error sending message:", error);
      antMessage.error("Failed to send message.");
    } finally {
      setSending(false);
    }
  };

  return (
    <Layout style={{ height: "100vh" }}>
      <Sider width={250} style={{ background: "#f5f5f5" }}>
        <Header
          style={{ background: "#008069", color: "#fff", textAlign: "center" }}
        >
          Contacts
        </Header>
        <List
          itemLayout="horizontal"
          dataSource={contacts.sort((a, b) => {
            const latestMessageA =
              messages[a] && messages[a][messages[a].length - 1]?.timestamp;
            const latestMessageB =
              messages[b] && messages[b][messages[b].length - 1]?.timestamp;

            return new Date(latestMessageB) - new Date(latestMessageA);
          })}
          renderItem={(contact) => {
            const latestMessage =
              messages[contact] &&
              messages[contact][messages[contact].length - 1];

            const previewText =
              latestMessage?.text ||
              (latestMessage?.media?.caption ? "[Media]" : "No messages yet");

            return (
              <List.Item
                style={{
                  padding: "16px",
                  cursor: "pointer",
                  backgroundColor:
                    contact === selectedContact ? "#e0f7fa" : "transparent",
                }}
                onClick={() => setSelectedContact(contact)}
                className={contact === selectedContact ? "active-contact" : ""}
              >
                <List.Item.Meta
                  avatar={<Avatar icon={<UserOutlined />} />}
                  title={
                    <Text strong>
                      {contact}{" "}
                      {latestMessage?.direction === "incoming" &&
                        latestMessage?.read === false && (
                          <span style={{ color: "red", marginLeft: "8px" }}>
                            NEW
                          </span>
                        )}
                    </Text>
                  }
                  description={
                    <Text type="secondary">
                      <small>{previewText}</small>
                    </Text>
                  }
                />
              </List.Item>
            );
          }}
        />
      </Sider>

      <Layout>
        <Header
          style={{ background: "#008069", color: "#fff", padding: "0 20px" }}
        >
          <Text style={{ color: "#fff" }}>Chat with {selectedContact}</Text>
        </Header>

        <Content
          ref={contentRef}
          style={{
            padding: "20px",
            background: "#e5ddd5",
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {loading ? (
            <Spin tip="Loading messages..." />
          ) : (
            <List
              itemLayout="horizontal"
              dataSource={messages[selectedContact] || []}
              renderItem={(item) => (
                <div
                  className={`message-bubble ${
                    item.direction === "incoming" ? "incoming" : "outgoing"
                  }`}
                >
                  <Text>{item.text}</Text>
                  <span className="timestamp">
                    {new Date(item.timestamp).toLocaleTimeString()}
                  </span>
                </div>
              )}
            />
          )}
        </Content>

        <Footer style={{ background: "#f0f0f0", padding: "10px 20px" }}>
          <Form
            onFinish={sendMessage}
            layout="inline"
            style={{ width: "100%" }}
          >
            <Form.Item
              name="message"
              rules={[{ required: true, message: "Enter a message!" }]}
              style={{ flexGrow: 2 }}
            >
              <TextArea
                rows={1}
                placeholder="Type a message..."
                autoSize={{ minRows: 1, maxRows: 3 }}
              />
            </Form.Item>
            <Button
              type="primary"
              shape="circle"
              icon={<SendOutlined />}
              htmlType="submit"
              loading={sending}
              style={{ marginLeft: 10 }}
            />
          </Form>
        </Footer>
      </Layout>
    </Layout>
  );
};

export default WhatsAppInterface;
